import Resource from './Resource';
import {
    buildApiUrl, buildApiUrlWithoutResource, extractData, getResourceUrl, getResourceName
} from './helper';
import { applyProjectInjectors } from '../ProjectHelper';


function applyAlertsStatus(project, alertsSummary) {
    project.alertsSummary = alertsSummary
    return project
};

function mergeAlertsStatusList(projects) {
    return alerts => {
        for (let i = 0; i < projects.data.length; i++) {
            applyAlertsStatus(projects.data[i], alerts.data[i])
        }
        return projects
    }
}

function mergeAlertsStatus(alertPromise) { // eslint-disable-line no-unused-vars
    return project => {
        return alertPromise.then(alerts => applyAlertsStatus(project, alerts))
    }
}

/* @ngInject */
export default class IAMService {
    constructor($http, $injector) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.$injector = $injector;

        this.getOrganization = this.ApiService.get;
        this.updateOrganization = this.ApiService.update;
        this.deleteOrganization = this.ApiService.remove;

        this.updateProject = this.ApiService.update;
        this.deleteProject = this.ApiService.remove;

        this.getMember = this.ApiService.get;
        this.updateMember = this.ApiService.update;
        this.deleteMember = this.ApiService.remove;
    }

    organizations(params) {
        return this.ApiService.list(getResourceUrl('organizations'), params, 'organizations');
    }

    createOrganization(data) {
        return this.ApiService.create(getResourceUrl('organizations'), data);
    }

    projects(params) {
        return this.ApiService
            .list(getResourceUrl('projects'), params, 'projects')
            .then(({ data, ...rest }) => ({
                data: data.map(applyProjectInjectors),
                ...rest
            }));
    }

    getProject(projectId) {
        return this.ApiService.get(getResourceName(['projects', projectId]))
            .then(applyProjectInjectors)
    }

    getProjectDevicesCount() {
        return this.ApiService.get(getResourceName(
            `projects/${this.$injector.get('ProjectManager').currentProjectId}/devices:count`), {})
    }

    getAlertStatusForList() {
        return projects => {
            const params = {
                projectNames: projects.data.map(e => e.name)
            }
            return this.ApiService.list('alerts:summary', params, 'projects')
                .then(mergeAlertsStatusList(projects)).catch(() => projects)
        }
    }

    createProject(data) {
        return this.ApiService
            .create(getResourceUrl('projects'), data)
            .then(applyProjectInjectors);
    }

    projectMembers(params) {
        return this.ApiService.list(getResourceName(
            ['projects', this.$injector.get('ProjectManager').currentProjectId],
            ['project.members']
        ), params, 'members');
    }

    organizationMembers(params) {
        return this.ApiService.list(getResourceName(
            this.$injector.get('ProjectManager').currentProject.organization,
            ['organization.members']
        ), params, 'members');
    }

    organizationMembersSummary(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/projects/-/members:summary?organization=${organizationName}`),
		    method: "GET",
	    }).then(extractData)
    }

    allMembershipsForMember(memberId) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/projects/-/members?memberId=${memberId}`),
		    method: "GET",
            params: {
                organization: this.$injector.get('ProjectManager').currentProject.organization
            }
	    }).then(extractData)
    }

    batchInviteMembersToProjects(memberships) {
        return this.ApiService.create(getResourceName(`projects/-/members:batchCreate`), { members: memberships });
    }

    batchDeleteMembersFromProjects(memberNames) {
        return this.ApiService.create(getResourceName(`projects/-/members:batchDelete`), { names: memberNames });
    }

    createProjectMember(data) {
        return this.ApiService.create(getResourceName(
            ['projects', this.$injector.get('ProjectManager').currentProjectId],
            ['project.members']
        ), data);
    }

    createOrganizationMember(data) {
        return this.ApiService.create(getResourceName(
            this.$injector.get('ProjectManager').currentProject.organization,
            ['organization.members']
        ), data);
    }

    roles(params) {
        return this.ApiService.list(getResourceUrl('roles'), params, 'roles');
    }

    projectPermissions(projectName = this.$injector.get('ProjectManager').currentProject.name) {
        return this.ApiService.list(getResourceName(
            projectName,
            ['project.permissions']
        ), {}, 'permissions').catch(() => ({ data: [] }));
    }

    organizationPermissions(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.list(getResourceName(
            organizationName,
            ['organization.permissions']
        ), {}, 'permissions').catch(() => ({ data: [] }));
    }

    getInviteUrl(memberName) {
        return this.$http.get(buildApiUrl(getResourceName(memberName, ['iam.member.inviteUrl']))).then(extractData);
    }

    getBillingInformation(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.get(getResourceName(
            organizationName, 
            ['organization.billingInformation']
        ), {}, 'billingInformation');
    }

    getBillingPlan(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.get(getResourceName(
            organizationName,
            ['organization.billingPlan']
        ), {}, 'billingPlan');
    }

    purchaseLicenses(params, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.create(getResourceName(
            organizationName,
            ['organization.purchaseLicenses']
        ), params);
    }
    
    updateBillingInformation(params, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.put(getResourceName(
            organizationName, 
            ['organization.billingInformation']), params);
    }

    listInvoices(params, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.list(getResourceName(
            organizationName,
            ['organization.invoices']
        ), params, 'invoices').catch(() => ({ data: [] }));
    }

    generateInvoicePDF(invoiceNumber, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.$http({
		    url: buildApiUrl(getResourceName(`${organizationName}/invoices/${invoiceNumber}`, ['organization.invoice.invoicePDF'])),
		    method: "GET",
		    responseType: "blob"
	    })
    }

    listSubscriptions(params, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.list(getResourceName(
            organizationName,
            ['organization.subscriptions']
        ), params, 'subscriptions').catch(() => ({ data: [] }));
    }

    getSubscriptionDevices(subscriptionId, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.get(getResourceName(
            `${organizationName}/subscriptions/${subscriptionId}`, 
            ['organization.subscription.devices']
        ), {}).catch(() => ({ data: [] }));
    }

    activateDeviceRenewal(subscriptionId, deviceId, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.create(getResourceName(
            `${organizationName}/subscriptions/${subscriptionId}`,
            ['organization.subscription.activateDevice']), { deviceId })
    }

    deactivateDeviceRenewal(subscriptionId, deviceId, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.ApiService.create(getResourceName(
            `${organizationName}/subscriptions/${subscriptionId}`,
            ['organization.subscription.deactivateDevice']), { deviceId })
    }

    getClaimDetails(identifier, orgId) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/claimInfo`),
		    method: "GET",
            params: {
                identifier,
                organization: `organizations/${orgId}`,
            }
	    }).then(extractData)
    }
    
    claim(kitIds, deviceIds) {
        return this.ApiService.create(getResourceName(
            `projects/${this.$injector.get('ProjectManager').currentProjectId}/devices:claim?dryRun=false`), { 
            kitIds,
            deviceIds,
        })
    }
    
    getSalesOrders(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/${organizationName}/salesorders`),
		    method: "GET",
	    }).then(extractData)
    }

    getKitsFromSalesOrder(salesOrderId, organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/${organizationName}/salesorders/${salesOrderId}/kits`),
		    method: "GET",
	    }).then(extractData)
    }

    getCustomerStatus(organizationName = this.$injector.get('ProjectManager').currentProject.organization) {
        return this.$http({
		    url: buildApiUrlWithoutResource(`/${organizationName}/paymentstatus`),
		    method: "GET",
            params: {
                project: `projects/${this.$injector.get('ProjectManager').currentProjectId}`
            }
	    }).then(extractData)
    }

    getLogo(projectName) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${projectName}:logo`),
            method: "GET",
            responseType: 'arraybuffer', // Important!
        }).then(response => {
            const contentType = response.headers('content-type');
            const data = response.data;
            return { contentType, data };
        });
    }

    setLogo(organizationName, base64Logo, contentType) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${organizationName}:logo`),
            method: "POST",
            data: {
                logo: base64Logo,
                contentType: contentType
            }
        })
    }

    deleteLogo(organizationName) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${organizationName}:logo`),
            method: "DELETE"
        })
    }

    listBIConnectorKeys(organization) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${organization}/biconnectors/-/keys`),
            method: "GET",
        })
    }

    deleteBIConnectorKey(organization, keyId) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${organization}/biconnectors/-/keys/${keyId}`),
            method: "DELETE",
        })
    }

    createBIConnectorKey(organization) {
        return this.$http({
            url: buildApiUrlWithoutResource(`/${organization}/biconnectors/-/keys`),
            method: "POST",
        })
    }
}
