

/* @ngInject */
export default class StudioPlanController {
    constructor(DialogService, billingPlan) {
        this.DialogService = DialogService;
        this.billingPlan = billingPlan;
    }

    $onInit() {
    }

    cancel() {
        this.DialogService.cancel();
    }
}
