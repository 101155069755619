import moment from 'moment';
import Config from 'services/config/local';
import { loadStripeSDK } from 'services/LazyLoading';

// Inform ES Lint that "Stripe" is a global variable (avoids linter errors)
/* global Stripe */

/* @ngInject */
export default class AddLicensesController {
    constructor(DialogService, ToastService, billingPlan, licensesInUse) {
        this.DialogService = DialogService;
        this.ToastService = ToastService;

        this.billingPlan = billingPlan
        this.licensesInUse = licensesInUse
    }

    get daysUntilRenewal() {
        const today = moment();
        const renewal = moment(this.renewalDate);
        return renewal.diff(today, 'days');
    }

    get renewalDateString() {
        const renewalMoment = moment(this.renewalDate);
        return `${renewalMoment.format('MMMM Do')}`;
    }

    get formattedStudioPlan() {
        return this.billingPlan.studioPlan.charAt(0) + this.billingPlan.studioPlan.slice(1).toLowerCase()
    }

    get showProratedSection() {
        return this.sensorLicenses.nextRenewal > this.sensorLicenses.currentlyOwned || this.cellularLicenses.nextRenewal > this.cellularLicenses.currentlyOwned;
    }

    $onInit() {

        // Use the billing plan to set the initial values used for calculating the new values
        this.sensorLicenses = {
            inUse: this.licensesInUse.sensors,
            currentlyOwned: this.billingPlan.sensorLicenses,
            nextRenewal: this.billingPlan.sensorLicensesForRenewal,
            add: 0,
            newTotal: this.billingPlan.sensorLicensesForRenewal
        };
        this.cellularLicenses = {
            inUse: this.licensesInUse.cellular,
            currentlyOwned: this.billingPlan.cconLicenses,
            nextRenewal: this.billingPlan.cconLicensesForRenewal,
            newTotal: this.billingPlan.cconLicensesForRenewal,
            add: 0
        };

        this.editSensorLicenses = false;
        this.editCellularLicenses = false;

        this.pricing = {
            sensor: this.billingPlan.centsPerSensorPerYear / 100,
            cellular: this.billingPlan.centsPerCconPerYear / 100,
        };

        this.renewalDate = new Date(this.billingPlan.nextRenewalDate);

        this.oldAnnualPrice = this.sensorLicenses.currentlyOwned * this.pricing.sensor + this.cellularLicenses.currentlyOwned * this.pricing.cellular;
        this.newAnnualPrice = null;
        this.proratedPrice = null;

        this.updateProratedPrice();
    }

    convertToCurrency(value) {
        return value.toLocaleString(undefined, { style: 'currency', currency: this.billingPlan.currency }); // Uses user locale for formatting when set to 'undefined'
    }

    updateProratedPrice() {

        if (this.sensorLicenses.nextRenewal < this.sensorLicenses.inUse) {
            this.proratedPrice = null
            return
        }
        if (this.cellularLicenses.nextRenewal < this.cellularLicenses.inUse) {
            this.proratedPrice = null
            return
        }

        this.sensorLicenses.newTotal = this.sensorLicenses.nextRenewal
        this.cellularLicenses.newTotal = this.cellularLicenses.nextRenewal
        this.sensorLicenses.add = Math.max(0, this.sensorLicenses.nextRenewal - this.sensorLicenses.currentlyOwned);
        this.cellularLicenses.add = Math.max(0, this.cellularLicenses.nextRenewal - this.cellularLicenses.currentlyOwned);

        const newAnnualPriceCheck = this.sensorLicenses.newTotal * this.pricing.sensor + this.cellularLicenses.newTotal * this.pricing.cellular;
        if (newAnnualPriceCheck === this.oldAnnualPrice) {
            this.newAnnualPrice = null;
        } else {
            this.newAnnualPrice = this.sensorLicenses.newTotal * this.pricing.sensor + this.cellularLicenses.newTotal * this.pricing.cellular;
        }

        const newSensorLicenses = this.sensorLicenses.add
        const newCellularLicenses = this.cellularLicenses.add
        const sensorCost = (newSensorLicenses * this.pricing.sensor * this.daysUntilRenewal) / 365;
        const cellularCost = (newCellularLicenses * this.pricing.cellular * this.daysUntilRenewal) / 365;
        this.proratedPrice = Math.round((sensorCost + cellularCost) * 100) / 100;
    }

    incrementLicenses(type, increment) {
        if (type === 'sensor') {
            this.sensorLicenses.nextRenewal += increment
        } else if (type === 'cellular') {
            this.cellularLicenses.nextRenewal += increment
        }
        this.updateProratedPrice();
    }

    async confirmLicenses() {
        
        // TODO: Allow customer to choose between CARD or INVOICE (if applicable)
        const paymentMethod = "CARD";
        // TODO: Get the subsidiary from the offer
        const subsidiary = "EU";
        // TODO: Remove when testing is done
        const fakeItTilYouMakeIt = true;
        
        let clientSecret;
        if (fakeItTilYouMakeIt) {
            clientSecret = "cs_test_a15g0rPG0lHrWKrIE7Wn0T0Cz3g9A9i2hpitTAV1AG8l7aI15auJAMU4YX_secret_fidwbEhqYWAnPydgaGdgYWFgYScpJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ3dgYWx3YGZxSmtGamh1aWBxbGprJz8na2BzYHcneCUl";
        } else {
            // Request the purchase of new licenses. This will set up an offer and an invoice in 
            // Netsuite. If the payment method is CARD, a payment URL will be returned which in
            // turn can be converted to an embedded checkout. If the payment method is INVOICE,
            // the invoice will be returned and the customer will need to pay it manually.
            const purchaseResp = await this.IAMService.purchaseLicenses({
                paymentMethod: paymentMethod,
                sensorLicenses: this.sensorLicenses.add,
                cellularLicenses: this.cellularLicenses.add,
                estimatedProratedPriceCents: Math.round(this.proratedPrice * 100),
            })

            // TODO: Only supporting CARD for now
            if (!purchaseResp.cardPayment) {
                this.ToastService.error("Error retrieving payment information");
                return;
            }

            // Convert the payment URL to a client secret to be used with the embedded checkout
            const embeddedCheckoutURL = `${purchaseResp.cardPayment.paymentUrl}?embed=true`;
            const embedResp = await fetch(embeddedCheckoutURL)
            if (!embedResp.ok) {
                this.ToastService.error("Error retrieving payment information");
                return;
            }
            const embedData = await embedResp.json();
            clientSecret = embedData.clientSecret;
        }

        // Load the Stripe SDK (only if payment method is CARD)
        await loadStripeSDK();
        const stripeKey = subsidiary === "US" ? Config.stripe.usPublishableKey : Config.stripe.euPublishableKey;
        const stripeClient = Stripe(stripeKey);
        
        this.showCheckout = true;

        this.checkout = await stripeClient.initEmbeddedCheckout({
            fetchClientSecret: () => {
                return clientSecret;
            },
            onComplete: () => {
                console.log('Checkout complete');
            }
        });

        this.checkout.mount('#stripe-checkout');
    }

    closeModal() {
        this.DialogService.cancel();
    }

    $onDestroy() {
        if (this.checkout) {
            this.checkout.destroy();
        }
    }
}