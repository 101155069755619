// This file contains functions to load external scripts and stylesheets dynamically.
// This is primarily to avoid loading in libraries that are not needed for the current page.

const loadScript = (src, globalName) => {
    // Check if the script is already loaded
    if (window[globalName]) {
        return Promise.resolve(window[globalName]);
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = reject;
        document.head.appendChild(script);
    });
}

const loadStylesheet = (href) => {
    // Check if the stylesheet is already loaded
    const existingLink = document.querySelector(`link[href="${href}"]`);
    if (existingLink) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        link.onload = () => resolve();
        link.onerror = reject;
        document.head.appendChild(link);
    });
}

export const loadMapboxSDK = () => {
    return Promise.all([
        loadStylesheet('https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css'),
        loadStylesheet('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.2/mapbox-gl-geocoder.css'),
        loadScript('https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.js', 'mapboxgl'),
        loadScript('https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.2/mapbox-gl-geocoder.min.js', 'MapboxGeocoder'),
    ]);
}

export const loadStripeSDK = () => {
    return loadScript('https://js.stripe.com/basil/stripe.js', 'Stripe');
}