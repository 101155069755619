import { Observable, Subject } from 'rxjs';
import { multicast } from 'rxjs/operators';
import {
    buildApiUrl, getResourceName, extractData
} from 'services/api/helper';
import { applyInjectors, hasOwnProperty } from 'services/utils';
import Resource from './Resource';
import ResourceStream from './ResourceStream';
import {
    // TILT-SUPPORT
    injectPrototypeDetails,
    injectStateValue,
    injectTypeIcon,
    injectOffline,
    injectLastSeen,
    injectTypeName,
    convertTimestamps,
    StreamParams
} from '../SensorHelper';

const injectComputedProps = entity => applyInjectors(entity, [
    // TILT-SUPPORT
    injectPrototypeDetails,
    injectStateValue,
    injectTypeIcon,
    convertTimestamps,
    injectOffline,
    injectLastSeen,
    injectTypeName,
]);

function setAlertStatus(device, alertsStatus) {
    device.alertsStatus = alertsStatus
    return device
}

function setAlertStatusList(devices, alertsStatuses) {
    for (let i = 0; i < devices.data.length; i++) {
        setAlertStatus(devices.data[i], alertsStatuses.data[i]);
    }
    return devices;
}

/* @ngInject */
export default class SensorService {
    constructor($http, ProjectManager, AuthService, $q) {
        this.$http = $http;
        this.ApiService = new Resource($http);
        this.ProjectManager = ProjectManager;
        this.AuthService = AuthService;
        this.$q = $q;

        this.cache = {};

        this.updateListInCache = this.updateListInCache.bind(this);
        this.updateItemInCache = this.updateItemInCache.bind(this);
    }

    updateCache(data) {
        this.cache[data.id] = data;
    }

    updateListInCache(list) {
        const { data } = list;
        data.forEach((item) => {
            this.updateCache(item);
        });
        return list;
    }

    updateItemInCache(item) {
        this.updateCache(item);
        return item;
    }

    // Returns a promise that resolves to an object containing both the found devices as 
    // well as a list of the device IDs that couldn't be found. The two fields are called
    // "devices" and "notFoundDeviceIDs" respectively.
    // 
    // The projectId can be provided as an optimization. When specified, this function 
    // will first look for devices in the specified project, before doing a lookup per 
    // device. Before doing any API requests, this function will check the cache for 
    // devices that have already been fetched.
    getFromCache(deviceIds, projectId = this.ProjectManager.currentProjectId) {

        const devices = [];
        let missingDevicesIds = [];

        // Find the devices we already have in cache.
        deviceIds.forEach((deviceId) => {
            if (this.cache[deviceId]) {
                devices.push(this.cache[deviceId]);
            } else {
                missingDevicesIds.push(deviceId);
            }
        });
        
        // Check if we have all the devices in cache.
        if (missingDevicesIds.length === 0) {
            return Promise.resolve({devices, notFoundDeviceIDs: []});
        }
        
        // If we have a project ID, try to find the missing devices in that project.
        let devicesInProjectPromise;
        if (projectId) {
            devicesInProjectPromise = this.listAllSensorPages({deviceIds: missingDevicesIds}, projectId);
        } else {
            // If we don't have a project ID, just resolve to an empty array to simulate no devices found.
            devicesInProjectPromise = Promise.resolve([]);
        }
        
        return devicesInProjectPromise.then((projectDevices) => {
            // Add the devices found in the project
            devices.push(...projectDevices);

            
            // Now do a lookup for the devices we still don't have.
            // If the device was not found (e.g. user does not have access), use a `null` value for the device.
            missingDevicesIds = missingDevicesIds.filter((deviceId) => !devices.find((device) => device.id === deviceId))
            const missingDevicesPromises = missingDevicesIds.map((deviceId) => this.lookupDevice(deviceId));
            
            return Promise.allSettled(missingDevicesPromises).then((missingDevicesResults) => {
                const notFoundDeviceIDs = [];

                missingDevicesResults.forEach((result, index) => {
                    if (result.status === 'fulfilled') {
                        // Add the devices found through individual lookups.
                        devices.push(result.value);
                    } else {
                        // The device was not found. Either the user does not have access or the device does not exist.
                        notFoundDeviceIDs.push(missingDevicesIds[index]);
                    }
                });

                // Return the final list of devices as well as the devices that couldn't be found.
                return {devices, notFoundDeviceIDs};
            });
        });
    }

    async listAllSensorPages(params, projectId = this.ProjectManager.currentProjectId) {
        let nextPageToken = "";
        const devices = [];

        do {
            // The no-await-in-loop ESLint rule is intended to ensure work that can be done in 
            // parallel is started with Promise.all(). Since pagination needs to be sequential,
            // we disable this rule here.
            // eslint-disable-next-line no-await-in-loop
            const page = await this.sensors({
                ...params,
                pageToken: nextPageToken
            }, projectId);

            devices.push(...page.data);
            nextPageToken = page.nextPageToken;
        } while (nextPageToken.length > 0);

        return devices;
    }

    sensors(params, projectId = this.ProjectManager.currentProjectId) {
        const name = getResourceName(
            ['projects', projectId],
            ['project.sensors']);

        // If params.deviceIds is more than 100, it will quickly lead to a URL that is 
        // longer than supported by some systems (4kB in the worst case). Each individual 
        // device will contribute 31-34 characters/bytes, so 100 devices will be 3100-3400 
        // characters/bytes plus the length of the rest of the URL. To avoid generating
        // too large URLs, we run multiple requests concurrently, each with 100 devices 
        // or less. These requests will always be exactly one page each, so we can run 
        // them concurrently.
        if (params.deviceIds && params.deviceIds.length > 100) {
            const deviceIds = params.deviceIds;

            const promises = [];
            for (let i = 0; i < deviceIds.length; i += 100) {
                const deviceIdsSlice = deviceIds.slice(i, i + 100);
                promises.push(this.sensors(
                    {...params, deviceIds: deviceIdsSlice}, 
                    projectId,
                ));
            }

            return Promise.all(promises)
                .then((responses) => {
                    const devices = responses.reduce(
                        (acc, response) => acc.concat(response.data), 
                        [],
                    );
                    return {data: devices, nextPageToken: ""};
                });
        }

        // Standard mechanism for fetching a page of devices.
        return this.ApiService.list(name, params, 'devices')
            .then(({ data, ...rest }) => ({
                data: data
                    .map(injectComputedProps),
                ...rest
            }))
            .then(this.updateListInCache);
    }

    getSensor(projectId, sensorId) {
        const name = getResourceName(['projects', projectId], ['project.sensors', sensorId])
        return this.ApiService.get(name)
            .then(injectComputedProps);
    }

    lookupDevice(id) {
        return this.getSensor('-', id)
    }

    createLabel(sensorName, data) {
        return this.ApiService.create(getResourceName(
            sensorName,
            ['project.sensor.labels']
        ), data);
    }

    mergeAlertStatus(alertsPromise) {
        return device => {
            let promise = alertsPromise
            if (promise === null) {
                promise = this.getAlertsStatus(device.name)
            }
            return promise.then(alert => setAlertStatus(device, alert));
        }
    }

    mergeAlertsStatuses() {
        return devices => {
            if (devices.data.length === 0) {
                return devices;
            }
            const devicenames = devices.data.map(d => d.name)
            return this.getAlertsStatuses(devicenames)
                .then(alerts => setAlertStatusList(devices, alerts))
        }
    }

    getAlertsStatus(resourceName) {
        return this.getAlertsStatuses([resourceName]).then(alerts => alerts.data[0])
    }

    getAlertsStatuses(resourceNames) {
        return this.ApiService.list('alerts:status', {resourceNames}, 'statuses')
    }

    muteAlert(sensorName, alertType) {
        return this.ApiService.create(
            getResourceName(sensorName, 'alerts:mute'), {"alertTypes": [alertType]})
    }

    unmuteAlert(sensorName, alertType) {
        return this.ApiService.create(
            getResourceName(sensorName, 'alerts:unmute'), {"alertTypes": [alertType]})
    }

    enableAlerts(sensorName) {
        return this.ApiService.create(
            getResourceName(sensorName, 'alerts:enable'), null)
    }

    disableAlerts(sensorName) {
        return this.ApiService.create(
            getResourceName(sensorName, 'alerts:disable'), null)
    }

    updateLabel(sensorName, labelKey, data) {
        return this.ApiService.update(getResourceName(
            sensorName,
            ['project.sensor.labels', labelKey]
        ), data);
    }

    deleteLabel(sensorName, labelKey) {
        return this.ApiService.remove(getResourceName(
            sensorName,
            ['project.sensor.labels', labelKey]
        ));
    }

    events(sensorName, params) {
        return this.ApiService.list(getResourceName(
            sensorName,
            ['project.sensor.events']
        ), params, 'events');
    }

    aggregatedEvents(data) {
        // Project ID can be different from the current project ID, e.g. when viewing organization alerts.
        const projectId = data.devices[0].split('/')[1] || this.ProjectManager.currentProjectId

        const name = getResourceName(
            ['projects', projectId],
            ['project.sensorAggregate']
        );

        return this.ApiService.create(name, data)
    }

    getStreamUrl(params) {
        const paramsUri = (new StreamParams(params)).getParamsQuery();
        const projectId = hasOwnProperty(params, 'projectId') ? params.projectId : this.ProjectManager.currentProjectId
        const baseUrl = buildApiUrl(
            getResourceName(
                ['projects', projectId],
                ['project.sensorStream']
            )
        );
        return () => `${baseUrl}?token=${this.AuthService.getAccessToken()}${paramsUri}`;
    }

    createObservableFromThingUpdates(deviceId, projectId = this.ProjectManager.currentProjectId) {
        return this.createObservableFromAllUpdates([deviceId], projectId);
    }

    createObservableFromAllUpdates(deviceIds, projectId = this.ProjectManager.currentProjectId) {
        const source = Observable.create((observer) => {
            const stream = this.subscribeToAllUpdates({ deviceIds, projectId }, (e) => {
                observer.next(e);
            });
            return () => { stream.stop(); };
        });
        const subject = new Subject();
        return source.pipe(multicast(subject)).refCount();
    }

    subscribeToThingUpdates(thingId, onUpdateCallback, config = {}) {
        return this.subscribeToAllUpdates({
            deviceIds: [thingId]
        }, onUpdateCallback, config);
    }

    subscribeToAllUpdates(params, onUpdateCallback, config = {}) {
        return new ResourceStream(this.getStreamUrl(params), onUpdateCallback, config);
    }

    transferSensors(projectName, data) {
        return this.ApiService.create(getResourceName(
            projectName,
            ['project.sensorTransfer']
        ), data);
    }

    getDeviceConfiguration(resourceName) {
        return this.ApiService.get(getResourceName(
            resourceName,
            ['project.sensor.configuration']
        ));
    }

    updateDeviceConfiguration(resourceName, data) {
        return this.ApiService.create(getResourceName(
            resourceName,
            ['project.sensor.configure']
        ), data);
    }

    getProjectConfiguration() {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/configuration`))).then(extractData)
    }

    updateProjectConfiguration(data) {
        return this.ApiService.update(getResourceName(`projects/${this.ProjectManager.currentProjectId}/configuration`), data);
    }


    batchUpdate(data) {
        return this.$http.post(buildApiUrl(
            getResourceName(
                ['projects', this.ProjectManager.currentProjectId],
                ['project.sensorBatchUpdate']
            )
        ), data).then(extractData);
    }

    calibrationInfo(deviceIds) {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices:calibrationInfo`)), { params: { deviceIds }}).then(extractData)
    }

    
    listReferenceValidations(deviceId) {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}/referencevalidations`))).then(extractData)
    }
    
    createReferenceValidation(deviceId, params) {
        return this.$http.post(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}/referencevalidations`)), params).then(extractData)
    }

    cancelReferenceValidation(deviceId, validationId) {
        return this.$http.post(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}/referencevalidations/${validationId}:cancel`))).then(extractData)
    }

    warrantyInfo(deviceIds) {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices:warrantyInfo`)), { params: { deviceIds }}).then(extractData)
    }


    downloadCertificatePDF(deviceId) {
        return this.$http({
		    url: buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}:certificate`)),
		    method: "GET",
		    responseType: "blob"
	    })
    }

    isInternetAccessible() {
        return this.$http.get('/ping.json', {
            params: {
                _: Date.now()
            },
            timeout: 1000
        })
            .then(() => true)
            .catch(() => false);
    }

    listSensorsSeen(cconId) {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${cconId}:sensorsSeen`)), { params: {}}).then(extractData)
    }

    sensorsSeenCount(cconId) {
        return this.$http.get(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices:sensorsSeenCount`)), { params: { cconId }}).then(extractData)
    }

    activateDevice(deviceId) {
        return this.$http.post(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}:activate`)))
            .then(extractData)
    }

    deactivateDevice(deviceId) {
        return this.$http.post(buildApiUrl(getResourceName(`projects/${this.ProjectManager.currentProjectId}/devices/${deviceId}:deactivate`)))
            .then(extractData)
    }

    licensesInUse() {
        // Returns a promise that resolves to an object containing the number of licenses in use for sensors and cellular ccons.
        return this.$http.get(buildApiUrl(getResourceName(`projects/-/devices:count`)), { params: { organization: this.ProjectManager.currentProject.organization }}).then(response => {
            const counts = response.data.deviceCounts
            const cellularLicensesUsed = counts.ccon?.licensesUsed || 0
            let sensorLicensesUsed = 0

            Object.entries(counts).forEach(([key, value]) => {
                if (key !== 'ccon') {
                    sensorLicensesUsed += value.licensesUsed
                }
            });

            return {
                sensors: sensorLicensesUsed,
                cellular: cellularLicensesUsed
            }
        })
    }
}
