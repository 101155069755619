import moment from 'moment';
import 'moment-timezone';
import { ORG_LOGO_CHANGED } from 'services/StudioEvents';
import { loadMapboxSDK } from 'services/LazyLoading';

// Used through "window.Highcharts"
import Highcharts from 'highcharts/js/highstock'; // eslint-disable-line 

import { truncateString } from 'services/utils';
import { CREATE_PROJECT, UPDATE_ORGANIZATION } from 'services/Permissions';
import { States } from '../../app.router';
import Config from 'services/config/local';

import ShowSecretPopupController from '../service-accounts/account-details/popups/show-secret/controller';
import ShowSecretPopupTemplate from '../service-accounts/account-details/popups/show-secret/template.html';

// Inform ES Lint that specific global variables exists (avoids linter errors)
/* global mapboxgl */
/* global MapboxGeocoder */

/* @ngInject */
export default class OrganizationDetailsController {

    constructor(IAMService, ToastService, ProjectManager, $q, RoleManager, $state, $scope, $rootScope, DialogService) {
        this.IAMService = IAMService;
        this.ToastService = ToastService;
        this.ProjectManager = ProjectManager;
        this.DialogService = DialogService;
        this.$q = $q;
        this.RoleManager = RoleManager;
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
    }

    get isOrganizationAdmin() {
        return this.RoleManager.hasOrganizationPermissionTo(CREATE_PROJECT);
    }

    get projectsWithLocationCount() {
        return this.projects.filter(p => p.location.latitude !== null && p.location.longitude !== null).length;
    }

    get isValidLogo() {
        return this.invalidLogoMessage === '';
    }

    get canUpdateBIConnectorKey() {
        return this.RoleManager.can(UPDATE_ORGANIZATION);
    }

    get reachedQuotaLimit() {
        return this.biconnectorKeys.length >= 10
    }

    $onInit() {
        this.project = this.ProjectManager.currentProject;
        this.organization = this.ProjectManager.currentProject.organization;

        this.orgName = this.ProjectManager.currentProject.organizationDisplayName;

        this.sensorCount = 0
        this.cloudConnectorCount = 0
        this.projectCount = 0
        this.membersCount = 0
        this.loadedOrgSummary = false;
        this.projects = []
        this.query = ''
        this.filteredProjects = [] // Used to limit list when a search query is entered
        this.editingLocation = false
        this.projectBeingEdited = null
        this.timeZoneToBeConfirmed = null
        this.loadingTimeZone = false
        this.readyToSaveLocation = false

        // Logo
        this.invalidLogoMessage = '';
        this.logoPreviewUrl = null;
        this.loadingLogo = true;
        this.getLogo();

        // BI Connector
        this.createBIConnectorKeyFormVisible = false;
        this.biconnectorKeys = [];
        this.listBIConnectorKeys();

        this.IAMService.organizationPermissions(this.organization).then(permissions => {
            if (permissions.data.includes(CREATE_PROJECT)) {
                const promises = []
                promises.push(this.fetchAllProjects().then(projects => {
                    projects.forEach(project => {
                        this.sensorCount += project.sensorCount
                        this.cloudConnectorCount += project.cloudConnectorCount
                        this.projectCount += 1
                    });
                    this.projects = [...projects]
                    this.filteredProjects = this.projects
                }))
                promises.push(this.fetchAllOrgMembers().then(members => {
                    this.membersCount = members.length
                }))
                promises.push(loadMapboxSDK())
                this.$q.all(promises).then(() => {
                    this.loadedOrgSummary = true

                    mapboxgl.accessToken = Config.mapbox.accessToken;
                    this.map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v12',
                        center: [0, 35],
                        zoom: 0.8,
                        projection: 'mercator' // Flat style
                    });
                    this.map.on('load', () => {
                        this.updateMap()
                        this.setupMapListeners()

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 50);

                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        }, 200);
                    })
                })

            }
        })
    }

    setupMapListeners() {
        this.isMouseOverMarkerOrPopup = false;

        this.marker = new mapboxgl.Marker() // eslint-disable-line

        // Event listener for mouseenter on the map
        this.map.on('mouseenter', 'unclustered-point', (e) => {
            this.map.getCanvas().style.cursor = 'pointer';
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.description;

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            this.popup.setLngLat(coordinates).setHTML(`
                <div class="popup-content">
                    <div class="popup-title">${e.features[0].properties.name}</div>
                    <div class="popup-description">${description}</div>
                    <md-button class="icon-button extra-small" id="open-project">
                        <span class="icon-button__text">Open Project</span>
                    </md-button>
                    <md-button class="icon-button extra-small" id="edit-location">
                        <span class="icon-button__text">Edit Location</span>
                    </md-button>
                </div>
            `).addTo(this.map);

            // Add click event listener to open project
            document.getElementById('open-project').addEventListener('click', () => {
                this.openProject(e.features[0].properties.id);
            });

            // Add click event listener to edit location
            document.getElementById('edit-location').addEventListener('click', () => {
                this.editLocation(this.projects.find(p => p.id === e.features[0].properties.id));
            });

            this.isMouseOverMarkerOrPopup = true;

            // Add event listeners to popup content
            const popupContent = document.querySelector('.mapboxgl-popup-content');
            popupContent.addEventListener('mouseenter', () => {
                this.isMouseOverMarkerOrPopup = true;
            });
            popupContent.addEventListener('mouseleave', () => {
                this.isMouseOverMarkerOrPopup = false;
                setTimeout(() => {
                    if (!this.isMouseOverMarkerOrPopup) {
                        this.popup.remove();
                    }
                }, 300);
            });
        });

        this.map.on('mouseleave', 'unclustered-point', () => {
            this.map.getCanvas().style.cursor = '';
            this.isMouseOverMarkerOrPopup = false;
            setTimeout(() => {
                if (!this.isMouseOverMarkerOrPopup) {
                    this.popup.remove();
                }
            }, 300);
        });

        this.map.on('click', (e) => {

            if (!this.editingLocation) return

            this.getTimeZone(e.lngLat)

            this.marker.remove();

            // Place a marker on the map
            this.marker = new mapboxgl.Marker()
                .setLngLat([e.lngLat.lng, e.lngLat.lat])
                .setDraggable(false)
                .addTo(this.map);
        });

        // Add Mapbox GL Geocoder for search functionality
        this.geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false  // Disable the default marker
        });

        this.map.addControl(this.geocoder);

        this.geocoder.on('result', (e) => {
            const lngLat = e.result.center;
            this.map.flyTo({ center: lngLat, zoom: 15 });
        });
    }

    updateMap() {

        // Sort projects based on if they have a location and ABC order.
        // The the projects without location will be at the end of the list but in ABC order.
        this.projects.sort((a, b) => {
            if (a.location.latitude === null && b.location.latitude === null) {
                return a.displayName.localeCompare(b.displayName)
            }
            if (a.location.latitude === null) {
                return 1
            }
            if (b.location.latitude === null) {
                return -1
            }
            return a.displayName.localeCompare(b.displayName)
        })


        const locations = []
        this.projects.forEach(project => {
            if (project.location.latitude === null || project.location.longitude === null) return
            locations.push({
                coords: [project.location.longitude, project.location.latitude],
                name: project.displayName,
                description: `Sensors: <b>${project.sensorCount}</b><br>Cloud Connectors: <b>${project.cloudConnectorCount}</b>`,
                id: project.id

            })
        })


        this.allFeatures = locations.map(loc => ({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: loc.coords },
            properties: { name: loc.name, description: loc.description, id: loc.id }
        }))

        const geojson = {
            type: 'FeatureCollection',
            features: this.allFeatures
        };

        this.map.addSource('markers', {
            type: 'geojson',
            data: geojson,
            cluster: true,
            clusterMaxZoom: 14, // Max zoom to cluster points on
            clusterRadius: 18 // Radius of each cluster when clustering points (defaults to 50)
        });

        this.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'markers',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#58ADEA',
                'circle-radius': 20
            }
        });

        this.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'markers',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Open Sans Bold', 'DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            },
            paint: {
                'text-color': '#fff'
            }
        });

        this.map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: 'markers',
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#58ADEA',
                'circle-radius': 8,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#fff'
            }
        });

        this.popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });
        this.$scope.$applyAsync()
    }

    queryChanged() {
        if (this.query === '') {
            this.filteredProjects = this.projects;
            return;
        }
        this.filteredProjects = this.projects.filter(project => project.displayName.toLowerCase().includes(this.query.toLowerCase()));
    }

    clearQuery() {
        this.query = '';
        this.queryChanged();
    }

    // Function to trigger popup based on project name
    showPopup(projectId) {
        // const features = this.map.querySourceFeatures('markers');
        const feature = this.allFeatures.find(f => f.properties.id === projectId);
        if (feature) {
            this.map.flyTo({
                duration: 800,
                center: feature.geometry.coordinates,
                zoom: this.map.getZoom() > 4.5 ? this.map.getZoom() : 4.5
            });
            this.popup.setLngLat(feature.geometry.coordinates).setHTML(`
                <div class="popup-content">
                    <div class="popup-title">${feature.properties.name}</div>
                    <div class="popup-description">${feature.properties.description}</div>
                </div>
            `).addTo(this.map);
        }
    }

    removePopup() {
        this.popup.remove();
    }

    getTimeZone(lngLat) {
        const lat = lngLat.lat;
        const lng = lngLat.lng;
        this.readyToSaveLocation = false
        this.loadingTimeZone = true
        this.$scope.$applyAsync()

        // Use the Mapbox Tilequery API to get the time zone information
        fetch(`https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery/${lng},${lat}.json?access_token=${mapboxgl.accessToken}`) // eslint-disable-line
            .then(response => response.json())
            .then(data => {
                if (data && data.features && data.features.length > 0) {
                    const timeZoneFeature = data.features[0];
                    const timeZoneId = timeZoneFeature.properties.TZID;
                    this.timeZoneToBeConfirmed = timeZoneId
                    this.readyToSaveLocation = true
                } else {
                    this.marker.remove();
                    this.ToastService.showSimpleTranslated('project_no_valid_timezone_found');
                }
            })
            .catch(error => {
                console.error('Error fetching time zone:', error); // eslint-disable-line no-console
                this.ToastService.showSimpleTranslated('project_timezone_wasnt_updated');
            }).finally(() => {
                this.loadingTimeZone = false
                this.$rootScope.$applyAsync()
            })
    }

    editLocation(project) {
        // Make map ready for user to place a marker
        this.editingLocation = true;
        this.projectBeingEdited = project;

        // Set lower opacity for marker being edited
        this.map.setPaintProperty('unclustered-point', 'circle-opacity', [
            'case',
            ['==', ['get', 'name'], project.displayName], 0.5,
            1
        ]);

        this.removePopup()
        this.readyToSaveLocation = false
        this.showConfirmLocationDialog = true
        this.$scope.$applyAsync()
    }

    confirmNewLocation() {
        // Save the new location
        const newLocation = this.marker.getLngLat();
        this.IAMService.updateProject(this.projectBeingEdited.name, {
            location: {
                timeLocation: this.timeZoneToBeConfirmed,
                latitude: newLocation.lat,
                longitude: newLocation.lng
            }
        }).then((updatedProject) => {
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects
            this.queryChanged() // Update incase there is an active query entered

            // Remove source and layers from map
            this.map.removeLayer('unclustered-point');
            this.map.removeLayer('cluster-count');
            this.map.removeLayer('clusters');
            this.map.removeSource('markers');
            this.marker.remove();

            this.updateMap();
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;

            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject(this.timeZoneToBeConfirmed);
            }

        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        })
    }

    cancelLocationEdit() {
        this.editingLocation = false;
        this.projectBeingEdited = null;
        this.marker.remove();

        // Reset opacity for all markers
        this.map.setPaintProperty('unclustered-point', 'circle-opacity', [
            'case',
            ['==', ['get', 'name'], ''], 1,
            1
        ]);

        this.map.setLayoutProperty('unclustered-point', 'visibility', 'visible');
        this.map.setLayoutProperty('clusters', 'visibility', 'visible');
        this.map.setLayoutProperty('cluster-count', 'visibility', 'visible');
        this.showConfirmLocationDialog = false;
    }

    clearLocation() {
        this.IAMService.updateProject(this.projectBeingEdited.name, {
            location: {
                timeLocation: "",
                latitude: null,
                longitude: null
            }
        }).then((updatedProject) => {
            this.removePopup()
            // Replace the project in the projects array
            const index = this.projects.findIndex(p => p.id === this.projectBeingEdited.id);
            this.projects[index].location = updatedProject.location;
            this.filteredProjects = this.projects
            this.queryChanged() // Update incase there is an active query entered

            // Remove source and layers from map
            this.map.removeLayer('unclustered-point');
            this.map.removeLayer('cluster-count');
            this.map.removeLayer('clusters');
            this.map.removeSource('markers');

            this.updateMap();
            this.editingLocation = false;
            this.projectBeingEdited = null;
            this.showConfirmLocationDialog = false;

            // Update time zone for the current project if it was the one being edited
            if (updatedProject.name === this.ProjectManager.currentProject.name) {
                this.ProjectManager.setCurrentProject(updatedProject);
                this.updateTimeZoneForCurrentProject("");
            }
        }).catch(serverError => {
            console.error('Error updating project location', serverError); // eslint-disable-line no-console
        })
    }

    updateTimeZoneForCurrentProject(timeZone) {
        moment.tz.setDefault(timeZone); // Update Moment timezone globally for Studio
        if (window.Highcharts) {
            window.Highcharts.setOptions({
                time: {
                    useUTC: true,
                    timezone: timeZone
                }
            })
        }

        this.$rootScope.$applyAsync();
        this.$rootScope.$broadcast('TIME_ZONE_CHANGED', { timeZone: timeZone });
    }

    openProject(projectId) {
        this.$state.go(States.SENSORS, {
            projectId: projectId
        });
    }

    fetchAllProjects() {
        const deferred = this.$q.defer();
        this.getProjectsPage(null, [])
            .then(projects => deferred.resolve(projects))
            .catch(() => {
                this.ToastService.showSimpleTranslated('projects_wasnt_fetched');
                deferred.reject();
            });
        return deferred.promise;
    }

    getProjectsPage(pageToken, previousData) {
        const requestParams = {
            organization: this.organization,
            pageToken
        }
        return this.IAMService
            .projects(requestParams)
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getProjectsPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    truncatedProjectDisplayName(displayName) {
        return truncateString(displayName, 30);
    }

    fetchAllOrgMembers() {
        const deferred = this.$q.defer();
        this.getMembersPage(null, [])
            .then(members => deferred.resolve(members))
            .catch(() => {
                this.ToastService.showSimpleTranslated('members_wasnt_fetched');
                deferred.reject();
            });
        return deferred.promise;
    }

    getMembersPage(pageToken, previousData) {
        const requestParams = {
            organization: this.organization,
            pageToken
        }
        return this.IAMService
            .organizationMembers(requestParams)
            .then(({ data, nextPageToken }) => {
                const allData = [...previousData, ...data];
                if (nextPageToken !== '') {
                    return this.getMembersPage(nextPageToken, allData);
                }
                return allData;
            });
    }

    getLogo() {
        this.IAMService.getLogo(this.project.name)
            .then((res) => {
                const contentType = res.contentType;
                const data = res.data;

                const byteArray = new Uint8Array(data);

                // Convert binary data to Blob
                const blob = new Blob([byteArray], { type: contentType });

                // Create a URL for the Blob
                // FileReader to create the preview URL
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.$scope.$apply(() => {
                        this.loadingLogo = false;
                        this.logoPreviewUrl = e.target.result; // base64 image URL for preview
                    });
                };
                reader.readAsDataURL(blob);

            })
            .catch((error) => {
                if (error.status === 404) {
                    // Do nothing if it's a 404, logo not found.
                } else {
                    // Handle other errors
                    console.error('Error fetching logo:', error); // eslint-disable-line no-console
                }
                this.loadingLogo = false;
            })
    }

    openLogoInput() {
        const inputElement = document.getElementById("logoInput");
        inputElement.addEventListener("change", this.handleLogoUpload.bind(this), false);
        inputElement.click();
    }

    handleLogoUpload(event) {
        const MAX_SIZE = 1024 * 1024; // 1MB in bytes
        const file = event.target.files[0];

        // Always reset input for consistent behavior
        event.target.value = '';

        if (!file) return;

        // Validate and update UI in one digest cycle
        this.$scope.$apply(() => {
            if (!file.type.startsWith('image/')) {
                this.invalidLogoMessage = `Unsupported file type: ${file.type}`;
                return;
            }

            if (file.size > MAX_SIZE) {
                const sizeMB = (file.size / (1024 * 1024)).toFixed(2);
                this.invalidLogoMessage = `File size too large: ${sizeMB}MB. Max size is 1MB`;
                return;
            }

            this.invalidLogoMessage = '';
        });

        if (this.invalidLogoMessage) return;

        // Convert to base64
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        }).then(base64Logo =>
            this.IAMService.setLogo(this.organization, base64Logo, file.type)
        ).then(() => {
            this.$rootScope.$broadcast(ORG_LOGO_CHANGED);
            this.ToastService.showSimpleTranslated('organization_logo_applied');
            this.getLogo();
        }).catch(error => {
            console.error('Logo upload failed:', error); // eslint-disable-line no-console
        });
    }

    deleteLogo() {
        this.IAMService.deleteLogo(this.organization).then(() => {
            this.$rootScope.$broadcast(ORG_LOGO_CHANGED);
        })
        this.logoPreviewUrl = null;
    }

    showDeleteConfirmation({ key }) {
        this.DialogService.confirm({
            title: 'Revoke Key?',
            textContent: `Do you really want to revoke "${key.id}"?`
                + ' Any client authenticated using this key will lose access.',
            ariaLabel: 'remove-service-account',
            ok: 'Remove',
            cancel: 'Cancel'
        }).then(() => {
            this.deleteBIConnectorKey(key);
        }).catch(() => { });
    }

    deleteBIConnectorKey(key) {
        this.IAMService.deleteBIConnectorKey(this.ProjectManager.currentProject.organization, key.id)
            .then(() => {
                this.biconnectorKeys = this.biconnectorKeys.filter(k => k.id !== key.id);
                this.ToastService.showSimpleTranslated('biconnector_key_was_removed');
            }).catch((serverResponse) => {
                this.ToastService.showSimpleTranslated('biconnector_key_wasnt_removed', {
                    serverResponse
                });
            });
    }

    createBIConnectorKey() {
        this.IAMService.createBIConnectorKey(this.ProjectManager.currentProject.organization)
            .then(({ data }) => {
                const keyId = this.isolateBIConnectorKeyID(data.key.name);
                const privateKeyData = data.key.privateKeyData;
                this.showBIConnectorSecretPopup(keyId, privateKeyData);
                this.biconnectorKeys.push({ id: keyId });
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('biconnector_key_wasnt_created', {
                    serverResponse
                });
            });

    }

    isolateBIConnectorKeyID(keyName) {
        return keyName.split('/').pop();
    }

    listBIConnectorKeys() {
        this.IAMService.listBIConnectorKeys(this.ProjectManager.currentProject.organization)
            .then(({ data }) => {
                const keys = data.keys;
                keys.forEach(key => {
                    this.biconnectorKeys.push({
                        id: this.isolateBIConnectorKeyID(key.name),
                    });
                });
            }).catch((serverResponse) => {
                this.toastService.showSimpleTranslated('biconnector_keys_wasnt_fetched', {
                    serverResponse
                });
            });
    }

    showBIConnectorSecretPopup(keyId, secret) {
        this.DialogService.show({
            controller: ShowSecretPopupController,
            controllerAs: '$ctrl',
            template: ShowSecretPopupTemplate,
            parent: document.body,
            openFrom: 'top',
            closeTo: 'top',
            fullscreen: true,
            locals: {
                keyId,
                secret
            }
        });
    }
}